//.modal .modal-dialog {
//  width: unset;
//
//  @include media-breakpoint-up(md) {
//    width: 100%
//  }
//}

.modal .modal-content {
  margin-left: 20px;
  margin-right: 20px;
}