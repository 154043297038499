$height-navbar: 80px;


$color-white: #fff;
$color-white-60:  rgba(255, 255, 255, 0.6);
$color-black: #000;
$color-black-60:  rgba(0, 0, 0, 0.6);

$color-red:       rgba(170, 0, 76, 1); // #A50034 => AA004C
$color-red-60:    rgba(170, 0, 76, 0.6);
$color-red-dark:  rgba(138, 11, 42, 1); // #8A0B2A

$color-gray-light: rgb(247, 247, 247); // #f7f7f7
$color-gray:      rgb(235, 235, 235); // #ebebeb
$color-gray-dark: rgb(160, 160, 160); // #A0A0A0
$color-gray-dark-a11y: #666666;

$color-font:      rgb(64, 64, 64); // #404040

.red {
  color: $color-red;
  a {
    color: $color-red;
  }
}
.bg-red {
  background-color: $color-red;
}
.bg-red-60 {
  background-color: $color-red-60;
}
.bg-red-dark {
  background-color: $color-red-dark;
}

.bg-red, .bg-red-60, .bg-red-dark,
.bg-black, .bg-black-60, .bg-black-dark {
  color: $color-white;
  a {
    color: $color-white;
  }
}

.gray {
  color: $color-gray;
  a {
    color: $color-gray;
  }
}
.bg-gray {
  background-color: $color-gray;
}
.bg-gray-light {
  background-color: $color-gray-light;
}
.bg-gray-dark {
  background-color: $color-gray-dark;
}

.white {
  color: $color-white;
  a {
    color: $color-white;
  }
}
.bg-white {
  background-color: $color-white;
}
.bg-white-60 {
  background-color: $color-white-60;
}

.black {
  color: $color-black;
  a {
    color: $color-black;
  }
}
.bg-black {
  background-color: $color-black;
}
.bg-black-60 {
  background-color: $color-black-60;
}



