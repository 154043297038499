.body-website2cols {
  .row-2-cols {
    .headerImage, .headerVideo {
      height: 400px;
    }
    h1 {
      margin-top: 30px;
    }


    .partner-wrapper {

      border: 1px solid $color-gray;
      margin-left: 0;
      margin-right: 15px;
      margin-bottom: 15px;
      width: calc(100% - 15px);

      .partner-logo {
        height:80px;
        margin:auto;
      }

    }



  }
  .col-right {
    margin-top: 80px;
  }
  @media (min-width: 992px) {
    .col-right {
      margin-top: 0px;
      h1 {
        margin-top: 97px;
      }
    }
  }
}