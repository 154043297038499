.joblist-boxlink {
  text-decoration: none;
}

.joblist-box {
  border: 1px solid $color-gray;
  transition: all 0.4s ease;
}

.joblist-box:hover {
  border-color: $color-red;
}

.joblist-box:hover .joblist-boxlink {
  color: $color-red;
  text-decoration: none;
  border: 0;
  transition: color 0.4s ease;
}