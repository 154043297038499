.headerImage {
  @media screen and (max-width: 1199px) {
    height:400px;
  }
  @media screen and (max-width: 900px) {
    height:300px;
  }
  @media screen and (max-width: 767px) {
    height:200px;
  }
}

.headerVideo {

  @media screen and (max-width: 1199px) {
    height:400px;
  }
  @media screen and (max-width: 900px) {
    height:300px;
  }

  position: relative;
  background-color: black;
  width: 100%;
  overflow: hidden;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
  }

}