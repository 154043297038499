
.component.component-iframe {

  iframe {
    height: 100%;
    width: 100%;
    border: none;
  }
}


.googleMapsIframe {
  iframe {
    height: 100%;
    width: 100%;
    border: none;
  }
}