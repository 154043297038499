.debug {
  background-color: black;
  color: lime;
  border: 1px solid lime;
  padding:5px;
  margin-top:30px;
  margin-bottom:15px;
  font-family: "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
}

body {
  background-color: $color-white;
  color: $color-font;
  font-family: "ff-dax-pro-condensed";
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 28px;
}

.container {
  max-width: 100%;
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px;

  }
}

main {
  margin-top: $height-navbar;
  min-height: 50vh;
  padding-bottom: 20px;

  @media (min-width: 1200px) {
    padding-bottom: 40px;
  }

  @media (min-width: 1600px) {
    padding-bottom: 80px;
  }
}

svg {
  width: 30px;
  height: 30px;
}
video,
img {
  max-width: 100%;
}
video {
  object-fit: cover;
}
.circled-red {
  border: 1px solid $color-red;
  border-radius: 50%;
}


.col-square {
  width: 50vw;
  height: 50vw;
}
.col-center-center  {
  div {
    height:100%;
  }
}

.bg-img,
.col-bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.box-hover-wrapper {
  a {
    height: 100%;
    width: 100%;
    display: inline-block;
  }
  .box-hover {
    margin-left: -15px;
    margin-right: -15px;

    height: 100%;
    position: absolute;
    width: 100%;
    bottom: 0;

    transition: height 0.4s ease;

    .text-1 {
      font-size: 36px;
      line-height: 46px;
      font-weight: 700;
      letter-spacing: 0.72px;
      width: 100%;
      //hyphens: auto;
      overflow-wrap: break-word;
    }
    .text-2 {
      display: none;
      transition: all 0.4s ease;
    }
  }
  .box-hover.bg-black-60 {
    .text-1 {

    }
  }
}
.box-hover-wrapper:hover {
  .box-hover {
    height: 50px;
    .text-1 {
      display: none;
    }
    .text-2 {
      display: inline;
    }
  }
}

.row-video .box-hover-wrapper.heightBox {
  height: 180px;
  @media (min-width: 992px) {
    height: 240px;
  }
}
.row-video .box-hover-wrapper {
  .box-hover {
    .text-1 {
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      letter-spacing: 0.72px;
      width: 100%;
      overflow-wrap: break-word;
      padding: 10px;
    }
    @media (min-width: 992px) {
      .text-1 {
        font-size: 24px;
        line-height: 34px;
        font-weight: 700;
        letter-spacing: 0.72px;
        width: 100%;
        overflow-wrap: break-word;
        padding: 10px;
      }
    }
    .text-2 {
      padding: 10px;
    }
  }
}
.row-video .box-hover-wrapper:hover {
  .box-hover {
    height: 70px;
  }
}

@media (max-width: 991px) {
  .p-0-mobile {
    padding:0;
  }
}


.anti-col {
  margin-left:-15px;
  margin-right:-15px;
}

.pt-30px {
  padding-top:30px;
}
.pb-30px {
  padding-bottom:30px;
}
.cursor-pointer {
  cursor: pointer;
}


.imageWrapper {
  height: 270px;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
  .imageWrapperInner {
    height: 270px;
    transition: all .5s;
    transform: scale(1.0);
  }
}
.col-hover:hover .imageWrapperInner {
  transform: scale(1.2);
}
.figcaption {
  display: none;
}
.card {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0;
}

@media (max-width: 1599px) {
  .col-xl1600-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.mh-300px {
  min-height: 300px;
}




a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
a.skip-main:active,
a.skip-main:focus {
  color: #fff;
  background-color: $color-red;
  left: 0;
  top: 10px;
  width: auto;
  height: auto;
  overflow: auto;
  padding: 5px;
  text-align: center;
  z-index: 10202;
}



.site-rbt .languageSwitch {
  display: none! important;
}