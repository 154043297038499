/*
@font-face {
  font-family: 'DaxWeb';
  src: url('/fonts/DaxWeb.eot');
  src: url('/fonts/DaxWeb.eot?#iefix') format('embedded-opentype'),
  url('/fonts/DaxWeb.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DaxWebCond';
  src: url('/fonts/DaxWebCond.eot');
  src: url('/fonts/DaxWebCond.eot?#iefix') format('embedded-opentype'),
  url('/fonts/DaxWebCond.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
*/

@font-face {
  // Dax Pro Cond Regular
  font-family: ff-dax-pro-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  // Dax Pro Cond Medium
  font-family: ff-dax-pro-condensed, sans-serif;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  // Dax Pro Cond Bold
  font-family: ff-dax-pro-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
}



a, a:hover {
  color: $color-black;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a img {
  text-decoration: none;
}
a.underline {
  text-decoration: underline;
  text-decoration-color: $color-red;
}

.col-text ul,
.component.component-text ul {
  color: $color-red;
  list-style: none;
  padding-left: 20px;
  font-weight: 600;
}
.col-text ul li,
.component.ul li {
  padding-bottom: 10px;
  a {
    color: $color-red;
  }
}
.col-text ul li::before,
.component.ul li::before {
  color: $color-red;
  content: '■';
  display: inline-block;
  margin-left: -1em;
  width: 1em;
}

ul.list-unstyled {
  list-style: none;
  li {
    padding-bottom: 15px;
    a {
      color: $color-font;
    }
  }
  li::before {
    content: none;
  }
}


h1, .h1,
h2, .h2 {
  font-weight: 700;
  margin-bottom: 30px;
}
p {
  margin-bottom: 30px;
  max-width: 720px;
}


.link-more:after {
  display: inline-block;
  content: ' ';
  margin-top:2px;
  margin-left:6px;
  background-size: 14px 14px;
  height: 14px;
  width: 14px;
  mask: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='angle-right' class='svg-inline--fa fa-angle-right fa-w-8' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='currentColor' d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'%3E%3C/path%3E%3C/svg%3E") no-repeat 50% 50%;
  background-color: currentColor;
}

.teaser-small, .link-more {
  display: inline-block;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.32px;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.h2-small {
  font-size: 20px;
  letter-spacing: 0.4px;
  font-weight: bold;
}