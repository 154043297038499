.logoWrapper {
  left:0;
  top:0;
  height:80px;
  width: 80px;
  a {
    left:0;
    top:0;
    height:80px;
    width: 80px;
  }
  .logo {
    margin:15px;
    height:50px;
    width: 50px;
  }
}
.logoWrapper:hover {
  background-color: $color-white;
}
.logoWrapperMobile {
  width: 180px;
  a {
    width: 180px;
  }
  .logo {
    margin:10px;
    height:60px;
    width: 163.44px;
  }
}

.navbar-brandWrapper {
  width: 170px;
  @media (min-width: 1024px) {
    width: 210px;
  }
  height: 80px;
  text-align: center;
  .navbar-brand {
    color: $color-gray-dark-a11y;
    margin: auto;
    margin-top: 20px;
    font-size: 24px;
    letter-spacing: -0.36px;
  }
}
.navbar-brandWrapper-rbk,
.navbar-brandWrapper-rbt {
  position: absolute;
  top:0;
}
.navbar-brandWrapper-rbk {
  left: 80px;
}
.navbar-brandWrapper-rbt {
  left: 250px;
  @media (min-width: 1024px) {
    left: 290px;
  }
}
.navbar-brandWrapper:hover {
  background-color: $color-white;
}
.navbar-brandWrapper:hover,
.navbar-brandWrapper.active {
  .word1 {
    color: $color-black;
  }
  .word2 {
    color: $color-red;
  }
}

#megamenu,
nav.navbar {

  .languageSwitch {
    height:80px;
    width: 80px;
    position: absolute;
    top: 0;
    right:80px;
    .dropdown-toggle {
      margin-top: 18px;
    }
    .dropdown-menu {
      margin: 0;
      width: 80px;
      min-width: 80px;
      border: 0;
      border-radius: 0;
      padding: 0;
      text-align: center;

    }
  }

  button.navbar-toggler {
    border: none;
    border-radius: 0;
    height:80px;
    width: 80px;
    position: absolute;
    top: 0;
    right:0;
    padding:0;
    margin:0;

    .navbar-icon {
      position: absolute;
      height: 3px;
      width: 25px;
      left: 26px;
      border-radius: 3px;
    }

  }

}

nav.navbar {

  padding:0;
  height: $height-navbar;

  .container {
    background-color: $color-gray-light;
    height: $height-navbar;
    justify-content: normal;
  }

  .languageSwitch {
    .dropdown-toggle:after {
      color: $color-red;
    }
    .dropdown-menu {

    }
  }
  .languageSwitch:hover {
    background-color: $color-white;
  }

  button.navbar-toggler {
    .navbar-icon {
      background-color: $color-red;
      border: 1px solid $color-red;
      border-radius: 3px;
    }
    .navbar-icon-1 {
      top: 28px;
      transform: rotate(0deg);
    }
    .navbar-icon-2 {
      top: 38px;
    }
    .navbar-icon-3 {
      top: 48px;
      transform: rotate(0deg);
    }
  }

  button.navbar-toggler:hover {
    background-color: $color-white;
  }

}

#megamenu {

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1040;

  .container-scroll {
    height: calc(100vh - 80px);
    overflow: scroll;
  }

  .container-navbar {
    height: $height-navbar;
  }

  .logo {
    height: 35px;
    margin-bottom:30px;
    background: white;
  }
  .logoWrapper .logo {
    left:0;
    top:0;
    height:80px;
    width: 180px;
    a {
      left:0;
      top:0;
      height:80px;
      width: 180px;
    }
  }
  .logoWrapperMobile .logo {
    background: $color-red;
    margin:10px;
    height:60px;
    width: 163.44px;
  }

  .logoWrapper:hover {
    background-color: $color-red;
  }

  .logo-megamenu {
    width: 272.41px;
    height: 100px;
    margin-top: -10px;
    margin-bottom: 30px;
  }

  .languageSwitch {
    background-color: $color-red-dark;
    .dropdown-toggle:after {
      color: $color-white;
    }

    .dropdown-menu {
      background-color: $color-red-dark;
      a {
        border-radius: 0;
      }
      a:hover {
        color: $color-red;
      }
    }
  }

  button.navbar-toggler {
    .navbar-icon {
      background-color: $color-white;
      border: 1px solid $color-white;
      border-radius: 3px;
    }
    .navbar-icon-1 {
      top: 38px;
      transform: rotate(-45deg);
    }
    .navbar-icon-2 {
      display:none;
    }
    .navbar-icon-3 {
      top: 38px;
      transform: rotate(45deg);
    }

  }

  .container-nav-cols {


    .col {

      padding-left:30px;
      padding-right:30px;

      .nav-lvl1, .nav-lvl2 {
        li:hover {
          a {
            @media (min-width: 992px) {
              font-weight: 700;
            }
            color: $color-white;
          }
        }

        li.hasChildren {
          position: relative;
          .arrow {
            width: 19px;
            height: 19px;
            position: absolute;
            float: right;
            right:30px;
            top:12px;
            transition: all 0.4s ease-in-out;
          }
          .arrowline {
            position: absolute;
            height: 0;
            border-top:1px solid $color-white;
            width: 10px;
            right:0;
            transition: all 0.4s ease-in-out;
            top: 21px;
            right: 38px;
          }
        }
        li:hover.hasChildren {
          .arrow {
            right:0px;
          }
          .arrowline {
            width: 40px;
            right: 8px;
          }
        }

      }

    }

  }

}

.searchIconWrapper {
  position: absolute;
  right: 160px;
  width: 80px;
  height: 80px;
  top: 0;
  button, a {
    margin:auto;
    margin-top: 18px;
  }
}

.searchIconWrapper:hover {
  background-color: $color-white;
}

.searchWrapper {
  background-color: $color-white;
  position: absolute;
  right: 240px;
  width: 0px;
  top: 0px;
  height: 80px;
  overflow: hidden;
  transition: all 0.4s ease;
  input {
    width:280px;
    padding:0;
    padding-left: 15px;
    padding-right: 15px;
    margin:0;
    margin-top:10px;
    margin-left:10px;
    background-color: $color-gray-light;
    border: 1px solid $color-gray-dark;
    font-weight: 600;
    font-size: 20px;
    height: 60px;
  }
}
.searchWrapper.active {
  width: 300px;
}



.container-suche {
  a {
    text-decoration: none;
  }
  p {
    margin-bottom: 20px;
  }
}

.autocomplete-suggestions {
  margin-top: 10px;
  background-color: white;
  padding: 10px;
  margin-left: -10px;
}
.autocomplete-suggestion:hover {
  cursor: pointer;
  font-weight: 700;
}


@media (min-width: 992px) {
  .border-lg-right {
    border-right: 1px solid #ffffff!important;
  }
}
@media (min-width: 1200px) {
  .border-xl-right {
    border-right: 1px solid #ffffff!important;
  }
}

.navbarWrapper {
  position: absolute;
  top:0;
  height: 80px;
  svg {
    margin-top:10px;
  }
}
.navbarWrapper-fcb {
  right: 240px;
  width: 111.44px;
  height: 59.98px;
  transition: all 0.4s ease;
  svg {
    width: 111.44px;
    height: 59.98px;
  }
}
.navbarWrapper-fcb.active {
  @media (max-width: 1299px) {
    display:none !important;
  }
  @media (min-width: 1300px) {
    right: calc(240px + 300px);
  }
}
.navbarWrapper-so {
  right: calc(240px + 111.44px);
  width: 180.02px;
  height: 60px;
  transition: all 0.4s ease;
  svg {
    width: 180.02px;
    height: 60px;
  }
}
.navbarWrapper-so.active {
  @media (max-width: 1299px) {
    display:none !important;
  }
  @media (min-width: 1300px) {
    right: calc(240px + 111.44px + 300px);
  }
}

.col-ctas {
  @media (min-width: 1200px) {
    a.btn {
      float:right;
    }
  }


  @media (max-width: 1199px) {
    .button-termin,
    .button-besuch,
    .button-zuweisen {
      padding: 15px;
      width: 250px;
      margin-bottom: 15px;
      width: calc(100% / 3);
      float: left;
      svg {
        position: relative;
        left:auto;
        margin-bottom: 10px;
      }
      span {
        position: relative;
        margin:auto;
        display: block;
      }
    }
    .button-termin.border-right,
    .button-besuch.border-right {
      border-right: 1px solid $color-red!important;
    }
  }

  @media (min-width: 1200px) {
    .button-termin.border-right,
    .button-besuch.border-right {
      border-right: none!important;
    }
  }

}


.nav-lvl2-mobile {
  transform:none !important;
  border: none !important;
  a {
    font-weight: 400;
  }
}
.nav-plus {
  position: absolute;
  right: 0;
  top: 5px;
  svg {
    width: 25px;
    height: 25px;
    transform: rotate(0deg);
    transition: all 0.15s ease-in-out;
  }
}

.nav-item.hasChildren.show
.nav-plus {
  svg {
    transform: rotate(90deg);
  }
}

.subsiteSwitchWrapper {
  .dropdown-toggle {
    width: 18px;
    height: 28px;
  }
  .dropdown-toggle:after {
    color: $color-red;
  }
  .dropdown-item {
    margin:0;
    padding:0;
  }
  .dropdown-menu {
    position: absolute;
    top: calc(80px - 26px);
    left: calc(-163.44px - 18px);
    border: none;
    border-radius: 0;
    margin:0;
    padding: 0;
    img {
      margin: 10px;
      height: 60px;
      width: 163.44px;
    }
  }
}

.searchIconWrapper.searchIconWrapperLink {
  @media (max-width: 349px) {
    display:none;
  }
  right: 80px;
  @media (min-width: 576px) {
    right: 160px;
  }
}

@media (min-width: 1200px) {
  .buttonWrapper {
    width: 250px;
    float: right;
  }
}

.site-rbt .col-ctas {

  @media (max-width: 1199px) {
    .button-termin,
    .button-besuch,
    .button-zuweisen {
      width: calc(100% / 2);
    }
  }

}