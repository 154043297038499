.accordion {

  padding-bottom: 50px;

  .card-header {
    background-color: rgba(0,0,0,.03);
    transition: all 0.4s ease;
    a {
      font-weight: 700;
      font-size: 24px;
    }
    a svg {
      float: right;
      transform: rotate(90deg);
      transition: all 0.4s ease;
    }
    a.collapsed svg {
      transform: rotate(0deg);
    }
  }
  .card-header:hover {
    background-color: $color-white;
    a.collapsed svg {
      transform: rotate(90deg);
    }
  }

}