.crumbs {

  padding-left:0;

  ul {
    padding-left:0;
    padding-bottom: 15px;
    @media (min-width: 992px) {
      padding-bottom: 0;
    }
  }

  span {
    padding-bottom: 0;
  }

  @media (min-width: 992px) {
    ul.list-unstyled li {
      display: list-item !important;
    }
  }
  ul.list-unstyled li a.red {
    color: $color-red;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}