.col-text {

  padding: 30px;

  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 992px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  .videoWrapper {
    margin: -30px;
    margin-bottom: 30px;

    margin-left: -15px;
    margin-right: -15px;

    @media (min-width: 992px) {
      margin-left: -50px;
      margin-right: -50px;
    }

    video {
      width: 100%;
    }

  }
  .socialMediaPost {
    margin: -30px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 0;
    @media (min-width: 992px) {
      margin-left: -50px;
      margin-right: -50px;
    }
  }

}

.text4 {
}

.row-cta-buttons {

  height: 100px;

  .col {
    text-align: center;
  }

  svg {
    position: absolute;
    left: 30px;
    margin-top: 20px;
    width: 60px;
    height: 60px;
  }

  span {

    display: inline-block;
    margin-top: 30px;
    line-height: 46px;
    letter-spacing: 0px;

    margin-left: 75px;
    font-size: 24px;

    @media (min-width: 1160px) {
      margin-left: 50px;
      font-size: 30px;
    }
  }

  a:hover {
    text-decoration: none;
  }
  div {
    transition: background-color .15s ease-in-out,
  }
  div.bgwrapper {
    background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 90.7 90.7' viewBox='0 0 90.7 90.7' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238a0b2a'%3E%3Cpath d='m47.6 11c0 4.6-3.8 8.4-8.4 8.4s-8.4-3.8-8.4-8.4 3.8-8.4 8.4-8.4c4.6 0 8.4 3.8 8.4 8.4z'/%3E%3Cpath d='m11.3 44.1c-.5-.1-.9-.5-.9-1.1s.5-1.1 1.1-1.1c32.8 2.3 61.3 20.1 78.1 46.2h-18.2c-12-22.7-34-39.3-60.1-44z'/%3E%3Cpath d='m73.2 43.8c.6 0 1-.5 1-1.1s-.5-1.1-1.1-1.1c-20.5-1-39.2-9.3-53.4-22.3h-18.5c17.8 15.4 41 24.7 66.3 24.7 1.9 0 3.8-.1 5.7-.2z'/%3E%3C/g%3E%3C/svg%3E");    background-repeat: no-repeat;
    background-position: right;
    background-size: 100px 70px;
  }
  div:hover {
    background-color: $color-gray-light;
    a{
      color: $color-red;
    }
  }
  div:hover.bgwrapper {
    background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 90.7 90.7' viewBox='0 0 90.7 90.7' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23fff'%3E%3Cpath d='m47.6 11c0 4.6-3.8 8.4-8.4 8.4s-8.4-3.8-8.4-8.4 3.8-8.4 8.4-8.4c4.6 0 8.4 3.8 8.4 8.4z'/%3E%3Cpath d='m11.3 44.1c-.5-.1-.9-.5-.9-1.1s.5-1.1 1.1-1.1c32.8 2.3 61.3 20.1 78.1 46.2h-18.2c-12-22.7-34-39.3-60.1-44z'/%3E%3Cpath d='m73.2 43.8c.6 0 1-.5 1-1.1s-.5-1.1-1.1-1.1c-20.5-1-39.2-9.3-53.4-22.3h-18.5c17.8 15.4 41 24.7 66.3 24.7 1.9 0 3.8-.1 5.7-.2z'/%3E%3C/g%3E%3C/svg%3E");  }


}


.col-hover.bg-red:hover {
  a {
    p, span, h2 {
      color: $color-white;
    }
  }
}


@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .link-more:after {
    display: none;
    content: "";
  }
}
