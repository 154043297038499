
.btn {
  border-radius: 0;
  border-style: solid;
  border-width: 2px;
  display: block;
  font-size: .9em;
  font-weight: 700;
  padding: .75rem 1rem;
  text-align: center;
  white-space: nowrap;
}

.button-red,
.button-dark {
  background-color: $color-red-dark;
  color: $color-white;
  position: relative;
  
  svg {
    position: absolute;
    height: 20px;
    width: 20px;
    fill: $color-white;
    left: 15px;
  }
}

.button-red {
  background-color: $color-red;
}
.button-dark:hover {
  background-color: $color-white;
  color: $color-red;
}



.button-termin,
.button-besuch,
.button-zuweisen {
  padding: 15px;
  width: 250px;
  margin-bottom: 15px;
  span {
    margin-left: 30px;
  }
}




.side-buttonWrapper {

  position: fixed;
  right: 0px;
  z-index: 1035;

  top: 100px;
  @media (min-width: 992px) {
    top: 170px;
  }

  .side-button {
    right: 0px;
    overflow: hidden;
    position: absolute;
    color: $color-white;
    display: inline-block;
    transition: width 0.4s ease;

    height: 50px;
    width: 50px;
    @media (min-width: 992px) {
      height: 80px;
      width: 80px;
    }
    svg {
      margin:7px;
      height: 36px;
      width: 36px;
      @media (min-width: 992px) {
        margin:15px;
        width: 50px;
        height: 50px;
      }
    }

    span {
      display: none;
    }

  }
  .side-button:hover {
    width: 300px;
    @media (min-width: 992px) {
      width: 300px;
    }
    a {
      color: $color-white;
      text-decoration: none;
    }
    span {
      color: $color-white;
      text-decoration: none;
      display: inline;
    }
  }

  .side-button-1 {
    top: 0px;
  }
  .side-button-2 {
    top: 60px;
    @media (min-width: 992px) {
      top: 100px;
    }
  }
  .side-button-3 {
    top: 120px;
    @media (min-width: 992px) {
      top: 200px;
    }
  }

}



.button {

  cursor: pointer;
  display: inline-block;
  min-height: 60px;
  padding-left: 30px;
  padding-right: 30px;

  svg {
    display: inline-block;
    margin-right: 30px;
    float: left;
  }
  span {
    float:right;
  }
}
a.button {
  svg {
    margin-top: 14px;
  }
  span {
    display: block;
    margin-top: 17px;
  }
}
.button.bg-red:hover {
  background-color: $color-gray-light;
  color: $color-red;
  text-decoration: none;
}


.button-gmaps {
  background-color: $color-white;
  color: $color-red;
  text-decoration: none;
}
.button-gmaps:hover {
  background-color: $color-red;
  color: $color-white;
}


.button-socialmedia {
  background-color: $color-red;
  border: none;
  border-radius: unset;
  padding: 15px;
  height: 60px;
  width: 60px;
  color: $color-white;
  svg {
    width: 30px;
    height: 30px;
  }
}
.button-socialmedia:hover {
  background-color: $color-white;
  color: $color-red;
}