.body-sectionSearch {
  .searchfield {
    width: calc(100% - 60px - 60px - 15px);
    max-width: 300px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .searchform .submit {
    width: 60px;
    height: 60px;
    margin-left: 0px;
    padding: 0;
    svg {
      margin:auto;
      float:none;
    }
  }
}