footer {

  .container {
    padding-top:70px;
    padding-bottom:70px;
    border-top: 5px solid $color-gray-dark;
  }

  .col-footer-logo {
    .logo-footer {
      width: 230px;
      height: 84.42px;
      margin-bottom: 20px;
      margin-top: -5px;
    }
    .copy {
      font-size:16px;
      line-height: 21px;
      letter-spacing: 0.32px;
      font-weight: normal;
      margin-left: 10px;
    }
  }

  .col-footer-navigation {
    li {
      padding-bottom: 0;
      a {
        font-weight: 700;
      }
    }
  }

  .col-footer-socialmedia {
    .button-socialmedia {
      margin-right: 15px;
      margin-bottom: 15px;
    }
    .button-socialmedia:last-child {
      margin-right: 0;
    }
    .button-socialmedia:hover {
      background-color: $color-gray-light;
    }
  }


  .col-footer-newsletter {
    input, button {
      width: 100%;
      max-width: 300px;
    }
    button {
      svg {
        margin:auto;
      }
    }
  }


}