.slider-news {
  .slide {
    height: 480px;
    .slide-text {
      padding: 15px;
      padding-top: 30px;
      padding-left:50px;
      height: 240px;
      padding-right:50px;
    }
    .slide-img {
      background-color: $color-red;
      height: 240px;
    }
    h2 {
      margin-bottom: 20px;
      line-height: 1.5;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px;
      height:30px;
    }

    .p-wrapper {
      width: calc(100%);
      margin: 0 0 1em 0;
      overflow: hidden;
    }
    .p-wrapper p {
      margin: 0;
    }

    .overflow {
      position: relative;
      overflow: hidden;
      padding-right: 1rem; /* space for ellipsis */
    }
    .overflow::before {
      position: absolute;
      content: "...";
      bottom: 0;
      right: 0;
    }
    .overflow::after {
      content: "";
      position: absolute;
      right: 0;
      width: 1rem;
      height: 1rem;
      background: white;
    }




  }

}

.slider-news-prev,
.slider-news-next {
  top: 105px;
  z-index:1001;

  height: 30px;
  width: 30px;
  position: absolute;
  border: 0;
  background: $color-red;
  color: $color-white;
  margin:0;
  padding:0;
  transition: width 0.4s ease;
}

.slider-news-prev {
  left: 10px;
  cursor: pointer;
}
.slider-news-next {
  right: 10px;
  cursor: pointer;
}
.slider-news-prev:hover {
  left: 8px;
}
.slider-news-next:hover {
  right: 8px;
}


.slider-testimonials {

  background-image: url('data:image/svg+xml;charset=UTF-8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="WhiteSmoke" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path></svg>');
  background-repeat: no-repeat;
  background-size: 170px;
  background-position-y: center;
  // height: 300px!important;

  .slide {
    //   height: 300px!important;
  }

  @media (min-width: 992px) {
    width: calc(100% - 120px);
    .slide {
      max-height:300px;
      padding-left:200px;
    }
  }

  .testimonial-title {
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0px;
    color: #404040;
  }


}

.slider-testimonials-prev,
.slider-testimonials-next {
  z-index:1001;
  right: 30px;

  height: 30px;
  width: 30px;
  position: absolute;
  border: 0;
  background: none;
  color: $color-red;
  margin:0;
  padding:0;
}

.slider-testimonials-prev {
  top: 30px;
  cursor: pointer;
}
.slider-testimonials-next {
  bottom: 30px;
  cursor: pointer;
}

.slider-testimonials-prev:hover {
  top: 28px;
}
.slider-testimonials-next:hover {
  bottom: 28px;
}


.slider-partner-single {
  overflow: hidden;
}