.component.component-imagelist {
  margin-left:-15px;
  margin-right:-15px;
  margin-bottom:50px;
  ul {
    width:100%;
    margin:0;
    padding:0;
    list-style-type: none;
    li{
      width: 50%;
      float: left;
      position: relative;
      img{
        float: left;
        width: 100%;
      }
      div {
        width: 100%;
        height: 100%;
        position:absolute;
        border:0px;
        transition: all 0.15s ease;
      }
    }
    li:hover {
      div {
        border:3px solid white;
      }
    }
  }
}

.component.component-imagelist.component-imagelist-col-4 {
  ul {
    li{
      width: 25%;
    }
  }
}
.component.component-imagelist.component-imagelist-col-3 {
  ul {
    li{
      width: calc(100% / 3);
    }
  }
}
.component.component-imagelist.component-imagelist-col-2 {
  ul {
    li{
      width: 50%;
    }
  }
}