.component.component-text {
  iframe {
    width: 100%;
    border: none;
  }

  ul li {
    padding-bottom: 10px;
    a {
      color: $color-red;
    }
  }
  ul li::before {
    color: $color-red;
    content: '■';
    display: inline-block;
    margin-left: -1em;
    width: 1em;
  }
  tr {
    vertical-align: top;
  }
  td {
    padding-right: 15px;
  }
}