.component.component-image {
  figure {
    img {

    }
    figcaption {
      font-style: italic;
    }
  }
}
@media (min-width: 576px) {
  .component.component-image.image-width-50 {
    figure {
      width: 50%;
    }
  }
  .component.component-image.image-width-75 {
    figure {
      width: 75%;
    }
  }
  .component.component-image.image-align-center {
    figure {
      margin-left:auto;
      margin-right:auto;
    }
  }
  .component.component-image.image-align-right {
    figure {
      float: right;
    }
  }
}