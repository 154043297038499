.body-websiteStandard,
.body-sectionNews,
.body-sectionEvents,
.body-sectionBlog,
.body-sectionServicenavigation,
.body-websitePartnerlist {

  h1 {
    margin-top:30px;
  }
  h2 {
    padding-top:30px;
    font-weight: 700;
  }
  .rowAsideContent1 h2 {
    padding-top:0;
  }
  .row-boxes {
    padding-top:30px;
  }
  .row-boxes + .row-boxes {
    padding-top:0px;
  }

  .component-imagelist {
    padding-top:50px;
  }

  .content-image {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
    max-width: calc(100% + 30px);
  }

}