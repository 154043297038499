.colorbox {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-block: 1rem;

  &.box-red {
    background-color: $color-red;
    color: $color-white;

    p,h3, li {
      color: $color-white;
    }
  }

  &.box-greylight {
    background-color: $color-gray-light;

    p,h3 {
      color: $color-black;
    }
  }

  &.box-greydark {
    background-color: $color-gray-dark;

    p,h3 {
      color: $color-white;
    }
  }

  &.box-black {
    background-color: $color-black;

    p,h3 {
      color: $color-white;
    }
  }
}