.row .row-boxes-team {

  .text-2 {

    font-size: 16px;
    line-height: 20px;
    text-align: center;

    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 28px;
    }

    @media (min-width: 1200px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (min-width: 1440px) {
      font-size: 20px;
      line-height: 28px;
    }

  }

}