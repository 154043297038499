

.partner-wrapper {

  border: 1px solid $color-gray;
  margin-left: 0;
  margin-right: 15px;
  margin-bottom: 15px;
  width: calc(50% - 15px);

  height: 150px;

  transition: all 0.4s ease;

  .partner-logo {
    max-height:80px;
    margin:auto;
    width: 100%;
  }

}
.partner-wrapper:hover {
  border: 1px solid $color-gray-dark;
}

.partnerList {
  .partner-wrapper {
    width: 100%;
  }
  .partner-logo {
    width:auto;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .partner-logo {
      width: 100%;
      height: 100px;
    }
  }
}


.bg-img-partner {
  background-size: contain;
  background-origin: content-box;
}