select, input {
  background-color: $color-gray-light;
  border: 1px solid $color-gray-dark;
  font-weight: 600;
  font-size: 20px;
  height: 60px;
  margin-bottom: 15px;
}
.submit {
  border:none;
  font-size: 20px;
  height: 60px;
  margin-bottom: 15px;
}

select:hover,
select:focus,
input:hover,
input:focus {
  background-color: $color-white;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-red;
  opacity: 1; /* Firefox */
}

.formSuccess {
  padding: 20px;
  background-color: $color-red;
  position: absolute;
  top: 0;
  width: 100%;

  p {
    color: $color-white;
    margin: 0;
    max-width: unset;
  }
}

.formWrapper {

  margin-top: 20px;

  .freeform-row, .row {

    .freeform-column, .col-12 {

      input, select, textarea {
        background-color: $color-gray-light;
        border: 1px solid $color-gray-dark;
        font-weight: 600;
        font-size: 20px;
        height: 60px;

        padding: 0 15px;

        border-radius: 0;
      }

      textarea {
        padding: 15px;
        min-height: 90px;
      }

      input:hover,
      input:focus,
      textarea:hover,
      textarea:focus {
        background-color: $color-white;
      }

      input[type=file] {
        width: auto;
        background: none;
        border: 0;
        margin: 0;
        padding: 0;
      }

      input[type=checkbox],
      input[type=radio] {
        height: auto;
      }

      select.freeform-input {
        //width: auto;
      }
    }
  }

  .freeform-row, .row {
    display: block;
    @media screen and (min-width: 700px) {
      display: flex;
    }
  }

  input[type=submit], input[type=reset],
  button[type=submit], button[type=reset] {
    background-color: $color-red;
    border: none;
    color: $color-white;
    padding: 16px 32px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    text-transform: uppercase;
  }
  input[type=submit]:hover, input[type=reset]:hover,
  button[type=submit]:hover, button[type=reset]:hover {
    background-color: $color-red-dark;
    border: none;
    color: $color-white;
  }

}


.freeform-row .freeform-column .freeform-label.freeform-required:after {
  color: $color-red!important;
}